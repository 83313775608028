import React, { Component } from 'react';
import AcordeonCursosDh from './secHolistica/AcordeonCursosDh';
import GaleriaDH from './secHolistica/GaleriaDH';
import JumboHolistica from './secHolistica/JumboHolistica';

class Holistica extends Component {
  render() {
    return (
      <div className="container-lg">
        <JumboHolistica />
        <AcordeonCursosDh />
        <GaleriaDH />
      </div>
    );
  }
}

export default Holistica;
