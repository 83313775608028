import React from 'react'
import Jumbo from '../../Parciales/Jumbo'

export default function JumboHolistica() {
    return (
        <Jumbo
          titulo="Capacitación Desarrollo Humano y Transpersonal"
          lead="Formación desde el interior."
          texto="Todos los cursos, pueden ser adaptados inteligentemente a las condiciones específicas del participante, del contratante o de los objetivos."
          accion="botón de acción"
        >
         {}
          <h5 >
            Cada vez está más claro que la capacitación técnica tiene buenos resultados si está bien realizada, pero tendrá mucho mejores niveles de productividad si es complementada con capacitación humana.
          </h5>
          <h6>
            .
          </h6>
        </Jumbo>
    )
}
