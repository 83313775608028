import React from 'react'
import Jumbo from '../../Parciales/Jumbo'

export default function JumboAutomatizacion() {
    return (
      <Jumbo
        titulo="Consultoría de Datos y Automatización"
        lead="VBA y Funciones avanzadas."
        texto="Hay una curva de aprendizaje importante al esperar que nuestro analista financiero o afín aprenda VBA para automatizar sus propios archivos de Excel. Más aún si la inversión en tiempo y esfuerzo no irán más allá de automatizar dos o tres archivos al año, aún cuando esos tres archivos le lleven 15 días de trabajo. Es ahí cuando el servicio de automatización lo puede realizar un externo."
        accion="botón de acción"
      >
        {/*  <GrupoTarjetas>
            <Tarjeta titulo="Programas y Plazos" pie="">
              <p>
                En algunas ocasiones es suficiente un curso o una secuencia de
                cursos para obtener resultados. En otras, el proceso es más
                eficiente con programas de capacitación a largo plazo, de uno o
                dos eventos al año. Algunas veces es necesario un sistema
                intensivo a corto plazo.
              </p>
            </Tarjeta>{" "}
            <Tarjeta titulo="Desarrollo a la medida" pie="">
              <p>
                El 95% de nuestros cursos son desarrollados a la medida de las
                necesidades. Es poco común que dos equipos de trabajo, aún en la
                misma empresa requieran el desarrollo de las mismas habilidades.
                Casi todas las dimensiones son ajustables: duración, alcances,
                estilo, sesiones, formato, lenguaje, etc.
              </p>
            </Tarjeta>{" "}
            <Tarjeta titulo="Consultoría" pie="">
              <p>
                Del mismo modo en que todos somos capaces de reconocer el
                hambre, pero no siempre sabemos cómo nutrirnos, las necesidades
                de capacitación pueden ser afinadas con un diagnóstico
                profesional y especializado. El diagnóstico de necesidades de
                capacitación es una de nuestras especialidades.
              </p>
            </Tarjeta>
            
          </GrupoTarjetas> */}
        <h6>
          Si una tarea en Excel te lleva más de 2 horas de arrastrar el mouse aunque sea una vez al mes...
        </h6>
        <h6>
          Si una tarea en Excel la realizas más de 10 veces al día aunque sólo te lleve 3 minutos...
        </h6>
        <h6>
          Si una tarea en Excel tiene tantos puntos de posible error que tienes un hoja con los pasos anotados...
        </h6>
        <h6>
          Si una tarea en Excel tardaste más en aprendértela que en aprender a hacer un café en la máquina nueva...
        </h6>
        <h5>Entonces, lo más probable, es que tu trabajo será mejor, más rápido, más cómodo, con menos errores y más divertido, con la automatización en Excel vía VBA, PowerPivot y PowerBI.</h5>
      </Jumbo>
    );
}
