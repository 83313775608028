export const cursosDesarrolloHumano = [
  {
    titulo: "Inteligencia Emocional",
    imgUrl: process.env.PUBLIC_URL + "/img/inteligenciaemocional.jpg",
    subtitulo: "El gobierno de las emociones",
    cuerpo:
      "Las emociones como una herramienta de comunicación, base del sexto sentido y simplemente una habilidad que si no ponemos a favor, se vuelve en nuestra contra.",
    pie: "Entre 3 y 12 horas.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Comunicación asertiva",
    imgUrl: process.env.PUBLIC_URL + "/img/comunicacionasertiva.jpg",
    subtitulo:
      "Comunicar no es sólo decir y escuchar, sino entender y transmitir",
    cuerpo:
      "La herramienta administrativa más utilizada en el análisis básico de información.",
    pie: "Entre 3 y 9 horas.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Autoestima",
    imgUrl: process.env.PUBLIC_URL + "/img/autoestima.jpg",
    subtitulo: "De la aceptación a la admiración y el amor",
    cuerpo:
      "La habilidad de hallar en nosotros mismos lo que sea necesario, no sólo para aceptar nuestra realidad, sino para reconocernos como una unidad integral.",
    pie: "Entre 9 y 16 horas en sesiones de 3.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Salud",
    imgUrl: process.env.PUBLIC_URL + "/img/salud.jpg",
    subtitulo: "Capacidad de cultivar las capacidades",
    cuerpo:
      "La salud tiene tantas facetas en nosotros que resulta sorprendente el poco cuidado que tenemos, la poca práctica para ejercerla y todas las oportunidades de mejorarla.",
    pie: "Entre 6 y 18 horas.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Liderazgo Parental",
    imgUrl: process.env.PUBLIC_URL + "/img/liderazgoparental.jpg",
    subtitulo: "Conoce la verdadera escuela para Padres.",
    cuerpo:
      "Ser padre o madre tiene muchos significados, muchas más complicaciones y aún más secretos. " +
      " \n" +
      " Uno de esos secretos está en afinar el rol que nos corresponde como figura parental.",
    pie: "Entre 12 y 20 horas.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Resiliencia",
    imgUrl: process.env.PUBLIC_URL + "/img/resiliencia.jpg",
    subtitulo: "Ante la adversidad ¡no te conviertas en adversario!",
    cuerpo:
      "Como una afortunada adaptación de la ingeniería, la resiliencia se presenta como una habilidad de importancia máxima en la sociedad actual.",
    pie: "Entre 2 y 7 horas",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Principio de Realidad y Deseo",
    imgUrl: process.env.PUBLIC_URL + "/img/comunicacionasertiva.jpg",
    subtitulo: "La realidad como punto de partida para gobernar mi destino.",
    cuerpo:
      "Últimamente fluctuamos entre pensar que la realidad es fija y que la realidad no existe. " +
      " \n" +
      "Pero adentrarnos en los procesos internos que nos conectan con ella, más allá de ella misma, es iluminador.",
    pie: "Entre 2 y 4 horas.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
];
  ///////////////////////////////////////////////////////////////////////////////////
  export  const cursosMicrosoft = [
    {
      titulo: "Excel",
      imgUrl: process.env.PUBLIC_URL + "/img/imagen.jpg",
      subtitulo: "Desde fórmulas hasta macros y funciones personalizadas",
      cuerpo:
        "La herramienta administrativa más utilizada en el análisis básico de información." +
        " \n" +
        "Muchos sabemos que con Excel se pueden hacer muchas cosas, pero pocos sabemos cuáles, cómo y cuándo. Por eso es importante la capacitación a la medida y no siempre los cursos estándar.",
      pie: "Con Excel siempre hay algo que se puede hacer mejor y más rápido.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "PowerBi",
      imgUrl: process.env.PUBLIC_URL + "/img/imagen.jpg",
      subtitulo: "El complemento indispensable para Excel.",
      cuerpo:
        "Si bien no todo lo que se hace en Excel se puede hacer en PowerBi ni viceversa, es claro que el análisis de datos y la presentación de tableros de información deben ser migrados a PowerBi. No es un sustituto de Excel, pero es su mejor compañero.",
      pie: "Nueva generación de análisis no experto.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "PowerPoint",
      imgUrl: process.env.PUBLIC_URL + "/img/imagen.jpg",
      subtitulo:
        "La comunicación visual formal, siempre será una herramienta indispensable.",
      cuerpo:
        "La mayoría somos predominantemente visuales y en la comunicación de información es muy notorio. " +
        " \n" +
        "Por eso requerimos explotar las herramientas disponibles, quizá no para hacernos expertos en PowerPoint, sino para ser creativos en la comunicación con dispositivas.",
      pie: "El punto clave de la información.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Macros Excel (VBA)",
      imgUrl: "",
      subtitulo: "Que la máquina haga cosas de máquina y el analista, analice.",
      cuerpo:
        "Dos terceras partes de las tareas realizadas en Excel pueden ser automatizadas con una inversión mínima de tiempo, pero para eso se requiere invertir en conocimiento. " +
        " \n" +
        "Aprovechar automatizaciones en Excel es muy parecido a usar una combinación de teclas Ctrl+N para poner Negritas.",
      pie: "Si te lleva más de una hora, automatízalo.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Word",
      imgUrl: "",
      subtitulo: "Tiene mala fama, pero Word no es tan malo.",
      cuerpo:
        "Tiene tan mala fama con las imágenes y las tablas, que olvidamos que su enfoque está en los textos. Y en el manejo de ellos hay mucho qué aprovechar de la oveja negra de Microsoft.",
      pie: "¿En dónde nacen los PDFs?",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Teams",
      imgUrl: "",
      subtitulo: 'También llamado "el Zoom de Microsoft"',
      cuerpo:
        "Puede haber otras plataformas de comunicación virtual, quizá algunas mejores, pero cuando el ambiente corporativo te dé limones: haz limonada. " +
        " \n" +
        "Es importante acortar la curva de auto-aprendizaje en materia de comunicación virtual, porque estamos expuestos a muchos vicios que deterioran el mensaje.",
      pie: "Nunca te levantes sin apagar cámara y micrófono.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Outlook",
      imgUrl: "",
      subtitulo: "Quizá en peligro de extinción, pero ¿ya tiene reemplazo?",
      cuerpo:
        "La mayor aportación que tuvo el correo electrónico fue dejar por escrito lo que de otra manera quedaba sólo en nuestra memoria humana. " +
        " \n" +
        "Esa es una amenaza que nos acecha de nuevo. Hagamos con el e-mail una reconciliación antes de que paguemos las consecuencias.",
      pie: "La evidencia nunca sobra.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
  ];
  export const cursosFilosofia = [
    {
      titulo: "Alquimia",
      imgUrl: process.env.PUBLIC_URL + "/img/imagen.jpg",
      subtitulo: "El arte filosófico de la transmutación mental",
      cuerpo:
        "Una disciplina mental que va desde la autoobservación hasta la mente absoluta. " +
        " \n" +
        "Un arte, sí, pero también una obra de diseño, construcción, ejecución y perfeccionamiento interno.",
      pie: "Del Plomo al Oro.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Magia",
      imgUrl: "",
      subtitulo: "Sucederle a las cosas, en vez de que las cosas me sucedan.",
      cuerpo:
        "Tradición, Poder y misterio entretejidos con filosofía y pasión por la naturaleza: Sí, la magia existe.",
      pie: "Las fuerzas de la naturaleza a nuestro favor.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Principios Universales",
      imgUrl: "",
      subtitulo: "Conócete a ti mismo y conocerás el universo.",
      cuerpo:
        "Una visión filosófica de cómo funciona el universo es útil, no sólo para comprenderlo, sino para extrapolar ese conocimiento a uno mismo y descubrir sin gran esfuerzo lo que podemos hacer para retomar las riendas de nuestra propia vida.",
      pie: "La Verdad es una, las verdades son muchas.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Heirmarmene",
      imgUrl: "",
      subtitulo:
        "Del mismo nombre que la diosa del destino, pero una misión más profunda.",
      cuerpo:
        "Conectado con una base científica de física y matemáticas, esta forma de comprender el pasado y el futuro hace explotar la imaginación, abriendo la mente a enormes posibilidades de crecimiento.",
      pie: "Todas las Imposibilidades.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Elementales",
      imgUrl: "",
      subtitulo:
        "Conocimiento ancestral que todas las culturas del mundo cultivaron.",
      cuerpo:
        "Hallar el común denominador entre todas las culturas del mundo no es una tarea obvia, pero tampoco es tan compleja. " +
        " \n" +
        "Simplemente es mirar con los ojos de la sabiduría lo que es evidente para los sentidos.",
      pie: "El quinto elemento.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Inteligencia Holística",
      imgUrl: process.env.PUBLIC_URL + "/img/.jpg",
      subtitulo: "Clasificación de la inteligencia por proceso neurológico.",
      cuerpo:
        "La Inteligencia es la capacidad de dar una respuesta adecuada ante un estímulo. " +
        " \n" +
        "Esta habilidad es fundamental para la evolución, no sólo como individuo a través de la adaptabilidad, sino como integrante de un sistema o participante en un colectivo.",
      pie: "Inteligencias colectivas.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Conscientización",
      imgUrl: "",
      subtitulo: "Darme cuenta y tomar en cuenta.",
      cuerpo:
        "El desarrollo de consciencia no es sólo un proceso de crecimiento personal, sino el fortalecimiento del andamiaje fundamental de la personalidad. " +
        " \n" +
        "Ampliar la consciencia repercute en el aprovechamiento de los recursos internos y la calidad de las relaciones humanas, productivas y sociales.",
      pie: "Lo que hacemos transforma, lo que soy trasciende.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
  ];
  export  const cursosConsciencia = [
    {
      titulo: "Conscientización Juvenil.",
      imgUrl: process.env.PUBLIC_URL + "/img/conscientizacionjuvenil.jpg",
      subtitulo: "Jornada de Conscientización Juvenil",
      cuerpo:
        "Cursos en formato de retiro que llevan a los jóvenes de entre 15 y 25 años a una comprensión divertida y a la vez profunda de la vida.",
      pie: "Labor Fecunda.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Perdón",
      imgUrl: process.env.PUBLIC_URL + "/img/perdon.jpg",
      subtitulo: "Porque te amo y te comprendo, no tengo nada qué perdonar.",
      cuerpo:
        "El perdón es sanación para quien siente el rencor, no para el ofensor." +
        " \n" +
        "Sin embargo, pocas veces nos ofrecen una estrategia clara de cómo superar el daño, sanar emocionalmente y equilibrarnos socialmente.",
      pie: "Perdonar no es olvidar ¿pero qué es?",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Resiliencia",
      imgUrl: process.env.PUBLIC_URL + "/img/resiliencia.jpg",
      subtitulo: "Ante la adversidad ¡no te conviertas en adversario!",
      cuerpo:
        "Como una afortunada adaptación de la ingeniería, la resiliencia se presenta como una habilidad de importancia máxima en la sociedad actual. " +
        " \n" +
        "No sólo es la capacidad de reponerse a la adversidad, sino también de elegir de quién ser adversario.",
      pie: "El famoso bambú y su no tan famosa dureza.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "No Negociables",
      imgUrl: process.env.PUBLIC_URL + "/img/nonegociables.jpg",
      subtitulo: "Todo es un negocio, pero no todo es negociable.",
      cuerpo:
        "La negociación es esencial en la convivencia, para la productividad y como recurso de adaptación. " +
        " \n" +
        "Pero no siempre, ni todo es negociable. Tener clara esta noción y conocer nuestros propios límites promueve la ecología e incrementa la productividad.",
      pie: "Capacidades esenciales.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Gobierno de Emociones",
      imgUrl: process.env.PUBLIC_URL + "/img/gobiernodeemociones.jpg",
      subtitulo: "Reconocer lo que siento, pero también decidirlo.",
      cuerpo:
        "Las emociones como una herramienta de comunicación, base del sexto sentido y simplemente una habilidad que si no ponemos a favor, se vuelve en nuestra contra. " +
        " \n" +
        "Las emociones son un mundo en sí mismas del cual podemos ser maestros y reyes.",
      pie: "Lo adecuado no siempre es lo correcto.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Geometría del Conflicto",
      imgUrl: process.env.PUBLIC_URL + "/img/geometriadelconflicto.jpg",
      subtitulo: "¿A qué juegas cuando juegas?",
      cuerpo:
        "La clave para comprender todos los conflictos humanos en todas sus dimensiones, contextos, eras y edades; A la vez, la clave para resolver todos los conflictos.",
      pie: "En el mundo real, el Lobo es víctima de Caperusita.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Anatomía de la Virtud",
      imgUrl: process.env.PUBLIC_URL + "/img/anatomiadelavirtud.jpg",
      subtitulo: "Virtud como antónimo de vicio: hábito que nutre.",
      cuerpo:
        "Las virtudes no significan 'ser bueno para quedar bien', sino ser congruente, coherente, competente, consistente y constante. " +
        " \n" +
        "Cultivar virtudes es cultivar el autoestima y el sentido de la vida.",
      pie: "Toda virtud es virtual.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
  ];

  export  const cursosEmpresa = [
    {
      titulo: "Liderazgo Total y toma de decisiones",
      imgUrl: process.env.PUBLIC_URL + "/img/conscientizacionjuvenil.jpg",
      subtitulo:
        "Un enfoque nutritivo del liderazgo y decisiones ecológicas con perspectiva",
      cuerpo:
        "El Liderazgo Total es un conjunto de capacidades con enfoque en la comunicación asertiva, inteligencia emocional, inteligencia social y planeación de consecuencias.",
      pie: "Entre 12 y 20 hrs. en sesiones de 3 hrs.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Consciencia de Equipos",
      imgUrl: process.env.PUBLIC_URL + "/img/conscientizacionjuvenil.jpg",
      subtitulo:
        "Proceso de construcción de equipos idealmente de 3 cursos al año.",
      cuerpo:
        "Enfoque innovador de la consciencia para un ambiente laboral nutritivo. " +
        " \n" +
        "Con base en el proceso de gestación y madurez de los equipos de trabajo, el participante comprende no sólo su lugar en el equipo, sino su función y fuerza.",
      pie: "Entre 20 y 30 hrs. en sesión intensiva de 2 días, 2 veces al año.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Atención al cliente",
      imgUrl: process.env.PUBLIC_URL + "/img/conscientizacionjuvenil.jpg",
      subtitulo:
        "Competencias de interacción basadas en la genética de ambas partes.",
      cuerpo:
        "Estrategias de negociación y comunicación eficiente, que habilita al participante para anticiparse a las condiciones de comunicación con el cliente, identificando el mejor canal, la energía adecuada y el estilo ideal para el servicio de alta calidad y la experiencia del cliente.",
      pie: "Entre 8 y 24 hrs. en sesiones de 2 hrs.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Motivación y Entusiasmo",
      imgUrl: process.env.PUBLIC_URL + "/img/conscientizacionjuvenil.jpg",
      subtitulo:
        "Un cambio de paradigma acerca de la energía que mueve a los equipos.",
      cuerpo:
        "El participante hallará en sí mismo los recursos adecuados para ser objeto de la motivación que el entorno le proporciona, pero también para crear el impulso interno que se convierta en entusiasmo.",
      pie: "4 horas.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Asimilación del cambio",
      imgUrl: process.env.PUBLIC_URL + "/img/conscientizacionjuvenil.jpg",
      subtitulo: "Competencias de adaptación y aprovechamiento del cambio.",
      cuerpo:
        "Convertir la evolución en un concepto cotidiano redefiniendo su sugnificado, reconociendo su aplicación y comprendiendo los procesos que la conforman.",
      pie: "6 horas.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Formación de Formadores",
      imgUrl: process.env.PUBLIC_URL + "/img/conscientizacionjuvenil.jpg",
      subtitulo:
        "Capacidades de comunicación, creación de materiales y temarios.",
      cuerpo:
        "Una de las aplicaciones más evidentes de la Inteligencia total que permite mejorar continuamente procesos didácticos desde antes y después de la instrucción, hasta el material físico, virtual, dinámico y el lenguaje integral del participante.",
      pie: "Entre 10 y 24 hrs. en sesiones de 2 o 3 hrs.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Creatividad y solución de problemas",
      imgUrl: process.env.PUBLIC_URL + "/img/conscientizacionjuvenil.jpg",
      subtitulo:
        "Enfoque innovador de la inteligencia racional para la creación de soluciones.",
      cuerpo:
        "Sinergia principalmente entre dos inteligencias: la racional y la emocional, que da como resultado habilidades en muchos aspectos del participante.",
      pie: "Entre 8 y 12 hrs.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Liderazgo Holístico",
      imgUrl: process.env.PUBLIC_URL + "/img/conscientizacionjuvenil.jpg",
      subtitulo:
        "Capacidades y Competencias que empoderan al líder, nutren al equipo y garantizan la ecología.",
      cuerpo:
        "Esquematización práctica de las habilidades gerenciales y de dirección. " +
        " \n" +
        "Un enfoque profundo del liderazgo, ya no como una habilidad profesional sino como una capacidad fundada en el desarrollo y nivel de consciencia de impacto integral en el participante.",
      pie: "Entre 20 y 30 hrs. en sesiones intensivas de 3 días.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
    {
      titulo: "Habilidades Individuales de productividad",
      imgUrl: process.env.PUBLIC_URL + "/img/conscientizacionjuvenil.jpg",
      subtitulo:
        "Paradigmas innovadores que desarrollan habilidades personales de alta importancia.",
      cuerpo:
        "Un conjunto de hábitos y trucos que combaten los enemigos de la productividad y a la vez nutren a los aliados.",
      pie: "Entre 4 y 6 horas.",
      aleatorio: Math.random() * (100 - 1) + 1,
      orden: 7,
    },
  ];