import React from "react";
import Acordeon from "../../Parciales/Acordeon";
import { ordenarAleatorio } from "../../utilidades/aleatorio";
import { pasos } from "../../constantes/automatizacion";

export default function AcordeonAutomatizacion() {
  const oa = ordenarAleatorio;
  oa(pasos, "orden");
  return <Acordeon items={pasos}></Acordeon>;
}
