import { CssBaseline, makeStyles } from '@material-ui/core';
import React, { Component } from 'react';

class sec1 extends Component {
  render() {
    return (
      <div className={styles.root}>
        <CssBaseline/>
        sección 1<h1>algo 5</h1>
        <h1>algo 5</h1>
        <h1>algo 5</h1>
        <h1>algo 5</h1>
      </div>
    );
  }
}
const styles=() => ({
  root: {
    minHeight: "100vh",
    backgroundImage: `url({${process.env.PUBLIC_URL + "/assets/fondo02.jpg"}})`,
    backgroundColor:"#acabf9",
    color:"#345312",
 
    margintop: "10em",
    height: "55vh",
    backgroundimage:' url(https://bootstrapmade.com/demo/themes/eStartup/img/video-bg.jpg)',
    backgroundposition: "center",
    position: 'relative',
    display: 'flex',
    justifycontent: 'center',
    alignitems: 'center',
  },
});

export default sec1;
