import React from "react";
import Acordeon from "../../Parciales/Acordeon";
import { ordenarAleatorio } from "../../utilidades/aleatorio";
import { cursosConsciencia } from "../../constantes/cursos";

export default function AcordeoncursosConsciencia() {
  const oa = ordenarAleatorio;
  oa(cursosConsciencia, "aleatorio");
  return <Acordeon items={cursosConsciencia}></Acordeon>;
}
