export const fundamentosITH = [
  {
    titulo: "Niveles de Consciencia",
    imgUrl: process.env.PUBLIC_URL + "/img/.jpg",
    subtitulo:
      "Esquematización del proceso evolución-cognitivo que va del necesitar al ser.",
    cuerpo:
      "Utilizando el lenguaje como síntoma y a la vez como herramienta obtenemos acceso a los procesos mentales que determinan el nivel de consciencia y con ello, la oportunidad de determinar sus funciones y formas.",
    pie: "Consciencia vs Conciencia.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Inteligencia Total",
    imgUrl: process.env.PUBLIC_URL + "/img/.jpg",
    subtitulo: "Clasificación de la inteligencia por proceso neurológico.",
    cuerpo:
      "La Inteligencia es la capacidad de dar una respuesta adecuada ante un estímulo. Esta habilidad es proporcional a la adaptabilidad, resiliencia y acceso a recursos. " +
      " \n" +
      "La capacitación debe redondear no sólo una o dos inteligencias, sino al menos todas las básicas.",
    pie: "Inteligencias individuales.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Inteligencia Holística",
    imgUrl: process.env.PUBLIC_URL + "/img/.jpg",
    subtitulo: "Clasificación de la inteligencia por proceso neurológico.",
    cuerpo:
      "La Inteligencia es la capacidad de dar una respuesta adecuada ante un estímulo. " +
      " \n" +
      "Esta habilidad es fundamental para la evolución, no sólo como individuo a través de la adaptabilidad, sino como integrante de un sistema o participante en un colectivo.",
    pie: "Inteligencias colectivas.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Capacidades No Negociables",
    imgUrl: process.env.PUBLIC_URL + "/img/.jpg",
    subtitulo: "Todo es un negocio, pero no todo es negociable.",
    cuerpo:
      "La negociación es esencial en la convivencia, para la productividad y como recurso de adaptación. Pero no siempre, ni todo es negociable. " +
      " \n" +
      "Tener clara esta noción y conocer nuestros propios límites promueve la ecología e incrementa la productividad.",
    pie: "Capacidades esenciales.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "CCHC",
    imgUrl: process.env.PUBLIC_URL + "/img/.jpg",
    subtitulo: "Capacidad, Competencia, Habilidad, Conducta",
    cuerpo:
      "Formación, facilitación, capacitación, instrucción y entrenamiento son actividades enfocadas en la expansión de las Capacidades o la determinación de las conductas. " +
      " \n" +
      " La diferencia entre ellas radica en la profundidad y las estructuras comprometidas durante el proceso.",
    pie: "Capacitación=Ampliación de la capacidad.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Niveles Neurológicos",
    imgUrl: process.env.PUBLIC_URL + "/img/.jpg",
    subtitulo: "Basado en el modelo PNL",
    cuerpo:
      "Una adecuación del modelo tradicional de PNL que se adapta mejor a los procesos no sólo de aprendizaje o terapéuticos, sino también a la búsqueda de sentido trascendente e inmediato.",
    pie: "Pilar de la Inteligencia.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
];
