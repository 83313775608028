import React, { Component } from 'react';
import AcordeonAutomatizacion from './secAutomatizacion/AcordeonAutomatizacion';
import JumboAutomatizacion from './secAutomatizacion/JumboAutomatizacion';
import GaleriaVBA from './secAutomatizacion/GaleriaVBA';
 


class Automatizacion extends Component {
  render() {
    return (
      <div className="container-lg">
        <JumboAutomatizacion />

        <div className="row">
          <div className="col-6">
            {" "}
            <AcordeonAutomatizacion />
          </div>{" "}
          <div className="col-6">
            <GaleriaVBA />
          </div>
          <></>
        </div>
      </div>
    );
  }
}

export default Automatizacion;
