import React, { Component } from 'react';

class Tarjeta extends Component {
  render() {
    return (
      <>
        <div className="card">
          
          <div className="card-body">
            <h5 className="card-title ">{this.props.titulo}</h5>
            <p className="card-text">{this.props.cuerpo}</p>
            
            {this.props.children}

            <p className="card-text">
              <small className="text-muted">{this.props.pie}</small>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Tarjeta;
