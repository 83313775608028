export const imagenesFrasesA = [
  {
    id: 1,
    src: "../img/a01.jpg",
    titulo: "Inteligencia total y holística",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 2,
    src: "../img/a02.jpg",
    titulo: "La consciencia",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 3,
    src: "../img/a03.jpg",
    titulo: "Geometría del conflicto",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 4,
    src: "../img/a04.jpg",
    titulo: "Literatura",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 5,
    src: "../img/a05.jpg",
    titulo: "Inteligencia holística",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 5,
    src: "../img/a06.jpg",
    titulo: "Argumento de vida",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 5,
    src: "../img/a07.jpg",
    titulo: "Inteligencia total y holística",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 5,
    src: "../img/a08.jpg",
    titulo: "Inteligencia Emocional",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 5,
    src: "../img/a09.jpg",
    titulo: "Gobierno de Emociones",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 5,
    src: "../img/a10.jpg",
    titulo: "Alquimia mental",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 11,
    src: "../img/a11.jpg",
    titulo: "Niveles de Consciencia",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 12,
    src: "../img/a12.jpg",
    titulo: "Liderazgo parental",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 13,
    src: "../img/a13.jpg",
    titulo: "Ciudadano del Universo",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 14,
    src: "../img/a14.jpg",
    titulo: "Caos y Orden",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 15,
    src: "../img/a15.jpg",
    titulo: "Toma de decisiones",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id:16,
    src: "../img/a16.jpg",
    titulo: "Perdón y Rencor",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 17,
    src: "../img/a17.jpg",
    titulo: "Alquimia mental",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {// se cambió nombre de archivo
    id: 18,
    src: "../img/a18.jpg",
    titulo: "Principio de Realidad",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 19,
    src: "../img/a19.jpg",
    titulo: "Consciencia y Conciencia",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 20,
    src: "../img/a20.jpg",
    titulo: "Lideraazgo Parental",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 21,
    src: "../img/a21.jpg",
    titulo: "Alquimia Mental",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 22,
    src: "../img/a22.jpg",
    titulo: "Amor Trascendente",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 23,
    src: "../img/a23.jpg",
    titulo: "Autoestima y Amor a sí mismo",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 24,
    src: "../img/a24.png",
    titulo: "Filosofía Hermética",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 25,
    src: "../img/a25.jpg",
    titulo: "No negociables",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 26,
    src: "../img/a26.jpg",
    titulo: "Gobierno de Emociones",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 27,
    src: "../img/a27.jpg",
    titulo: "Evolución",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 28,
    src: "../img/a28.jpg",
    titulo: "inteligencia Total",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 29,
    src: "../img/a29.jpg",
    titulo: "Principio de Realidad",
    texto: " ",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
];

export const imagenesFrasesB = [
  {
    id: 1,
    src: "../img/b01.png",
    titulo: "Inteligencia Total",
    texto: "Temario básico de consciencia",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 2,
    src: "../img/b02.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 3,
    src: "../img/b03.png",
    titulo: "Inteligencia Total",
    texto: "Ciudadano del Unniverso",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 4,
    src: "../img/b04.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 5,
    src: "../img/b05.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 6,
    src: "../img/b06.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 7,
    src: "../img/b07.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 8,
    src: "../img/b08.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 9,
    src: "../img/b09.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 10,
    src: "../img/b10.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 11,
    src: "../img/b11.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 12,
    src: "../img/b12.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 13,
    src: "../img/b13.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 14,
    src: "../img/b14.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 15,
    src: "../img/b15.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id:16,
    src: "../img/b16.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 17,
    src: "../img/b17.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 18,
    src: "../img/b18.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 19,
    src: "../img/b19.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 20,
    src: "../img/b20.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 21,
    src: "../img/b21.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 22,
    src: "../img/b22.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 23,
    src: "../img/b23.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 24,
    src: "../img/b24.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 25,
    src: "../img/b25.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id: 26,
    src: "../img/b26.png",
    titulo: "Inteligencia Total",
    texto: "Desarrollo Sistémico Empresarial",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
];

export const imagenesVBA = [
  {
    id: 1,
    src: "../img/vba01.png",
    titulo: "",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 1,
  },
  {
    id: 2,
    src: "../img/vba02.png",
    titulo: "",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 2,
  },
  {
    id: 3,
    src: "../img/vba03.png",
    titulo: "",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 3,
  },
  {
    id: 4,
    src: "../img/vba04.png",
    titulo: "",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 4,
  },
  {
    id: 5,
    src: "../img/vba05.png",
    titulo: "",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 5,
  },
  {
    id: 6,
    src: "../img/vba06.png",
    titulo: "",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 6,
  },
  {
    id: 7,
    src: "../img/vba07.png",
    titulo: "",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    id:8,
    src: "../img/vba08.png",
    titulo: "",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 8,
  },
  {
    id: 9,
    src: "../img/vba09.png",
    titulo: "",
    texto: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 9,
  },
];