import React from "react";
import Jumbo from "../../Parciales/Jumbo";
import AcordeoncursosFilosofia from "./AcordeoncursosFilosofia";

export default function JumboFilosofia() {
  return (
    <div className="container-sm">
      {" "}
      <Jumbo
        titulo="Formación en Filosofía"
        lead="φιλοσοφία"
        texto="El origen de nuestra metodología tanto para la capacitación como para la consultoría en desarrollo humano es un mosaico de poderosos conceptos filosóficos y cuerpos de conocimiento que en conjunto crean una visión holística de la consciencia."
        accion="botón de acción"
      >
        <h4>Conocimiento y sabiduría para el aprendizaje.</h4>
        <h5>
          El significado de lo aprendido no tiene futuro si no se ancla con el
          ser.
        </h5>
        <p>
          Aún los cursos técnicos de habilidades computacionales tienen
          componentes vinculados con nuestra filosofía, ya que nuestros procesos
          de diseño, instrucción y evaluación son construidos con estas bases al
          igual que los objetivos.
        </p>{" "}
        <p>
          Como alternativa, también pueden ser impartidos estos temas a los
          interesados, bajo demanda para grupos cerrados o eventualmente en
          cursos abiertos al público.
        </p>{" "}
        <AcordeoncursosFilosofia></AcordeoncursosFilosofia>
      </Jumbo>{" "}
    </div>
  );
}
