import React from "react";
import Acordeon from "../../Parciales/Acordeon";
import { ordenarAleatorio } from "../../utilidades/aleatorio";
import { cursosFilosofia } from "../../constantes/cursos";

export default function AcordeoncursosFilosofia() {
  const oa = ordenarAleatorio;
  oa(cursosFilosofia, "aleatorio");
  return <Acordeon items={cursosFilosofia}></Acordeon>;
}
