import React from "react";
import Acordeon from "../../Parciales/Acordeon";
import { ordenarAleatorio } from "../../utilidades/aleatorio";
import { cursosMicrosoft } from "../../constantes/cursos";

export default function AcordeonCursosMicrosoft() {
  const oa = ordenarAleatorio;
  oa(cursosMicrosoft, "aleatorio");
  return <Acordeon items={cursosMicrosoft}></Acordeon>;
}
