import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
export class BootstrapCarousel extends Component {
  render() {
    return (
      <div className="container-fluid">
        <Carousel fade>
          {this.props.items.map((elemento, index) => {
            return (
              <Carousel.Item
                
                key={index}
                id={"id" + index}
              >
                <img
                   
                  className="d-block w-100"
                  src={`${process.env.PUBLIC_URL + elemento.src}`}
                  alt={elemento.src}
                />
                <Carousel.Caption>
                  <h6>{elemento.titulo} </h6>
                  <p  >{elemento.texto}</p>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}

         
        </Carousel>
      </div>
    );
  }
}

export default BootstrapCarousel;
