import React from "react";
import { ordenarAleatorio } from "../../utilidades/aleatorio";

import BootstrapCarousel from "../../Parciales/BootstrapCarousel";
import {  imagenesFrasesB } from "../../constantes/Imagenes";

export default function GaleriaDH() {
  const oa = ordenarAleatorio;
  oa(imagenesFrasesB, "aleatorio");
  return <BootstrapCarousel items={imagenesFrasesB}></BootstrapCarousel>;
}
