import React from "react";
import { serviciosConsciencia } from "../../constantes/servicios";
 
import Acordeon from "../../Parciales/Acordeon";
import { ordenarAleatorio } from "../../utilidades/aleatorio";


export default function AcordeonServiciosConsciencia() {
  const oa = ordenarAleatorio;
  oa(serviciosConsciencia, "aleatorio");
  return <Acordeon items={serviciosConsciencia}></Acordeon>;
}
