import React from "react";

import Jumbo from "../../Parciales/Jumbo";
import AcordeonCursosEmpresas from "./AcordeonCursosEmpresas";

export default function JumboIth() {
  return (
    <Jumbo
      titulo="Inteligencia Total"
      lead="y Holística"
      texto="Capacitación Sistémica Empresarial en Tecnología, Desarrollo Humano y Transpersonal."
      accion="botón de acción"
    >
      <h4>Origen</h4>
      <h5>Fundada en 2004 y con experiencia desde 1990.</h5>
      <p>
        Somos una empresa mexicana. Nacimos de la necesidad de aplicar al
        entorno empresarial conocimientos y propuestas del desarrollo de
        consciencia, sin que esto nos alejara de la Tecnología de la
        información.
      </p>
      <h4>Enfoque</h4>
      <h5>Desarrollo Sistémico. </h5>
      <p>
        Estamos concentrados en el desarrollo de la inteligencia, tanto humana
        como tecnológica. Ponemos un matiz informático al desarrollo de
        habilidades suaves. Ponemos un matiz humano al desarrollo de habilidades
        técnicas.
      </p>
      <h4>Estilo</h4>
      <h6>Flexibles y Versátiles. </h6>
      <p>
        La concepción de una metodología propia nos permite ser altamente
        flexibles en la creación de competencias y la adopción de conductas, con
        lo cual, el cliente se descubre beneficiado. La profundidad de nuestras
        metodologías nos otorga versatilidad, de tal forma que podemos capacitar
        en tecnología o en desarrollo de competencias suaves con el mismo
        resultado de excelencia.
      </p>
      <AcordeonCursosEmpresas></AcordeonCursosEmpresas>
    </Jumbo>
  );
}
