import React, {  Component } from 'react';

class Jumbo extends Component {
  render() {
    return (
      <div className="jumbotron">
        <h1 className="display-4">{this.props.titulo}</h1>
        <p className="lead">{this.props.lead}</p>
        <p  className="">{this.props.texto}</p>
        <hr className="my-4" />
        {this.props.children}
      </div>
    );
  }
}

export default Jumbo;
