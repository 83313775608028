import React from 'react'
import Jumbo from '../../Parciales/Jumbo'

export default function JumboCompetencias() {
    return (
        <Jumbo
          titulo="Competencias a la medida"
          lead="Capacitación inteligente"
          texto="Todos los cursos, pueden ser adaptados inteligentemente a las condiciones específicas del participante, del contratante o de los objetivos."
          accion="botón de acción"
        >
         {/*  <GrupoTarjetas>
            <Tarjeta titulo="Programas y Plazos" pie="">
              <p>
                En algunas ocasiones es suficiente un curso o una secuencia de
                cursos para obtener resultados. En otras, el proceso es más
                eficiente con programas de capacitación a largo plazo, de uno o
                dos eventos al año. Algunas veces es necesario un sistema
                intensivo a corto plazo.
              </p>
            </Tarjeta>{" "}
            <Tarjeta titulo="Desarrollo a la medida" pie="">
              <p>
                El 95% de nuestros cursos son desarrollados a la medida de las
                necesidades. Es poco común que dos equipos de trabajo, aún en la
                misma empresa requieran el desarrollo de las mismas habilidades.
                Casi todas las dimensiones son ajustables: duración, alcances,
                estilo, sesiones, formato, lenguaje, etc.
              </p>
            </Tarjeta>{" "}
            <Tarjeta titulo="Consultoría" pie="">
              <p>
                Del mismo modo en que todos somos capaces de reconocer el
                hambre, pero no siempre sabemos cómo nutrirnos, las necesidades
                de capacitación pueden ser afinadas con un diagnóstico
                profesional y especializado. El diagnóstico de necesidades de
                capacitación es una de nuestras especialidades.
              </p>
            </Tarjeta>
            
          </GrupoTarjetas> */}
          <h5 >
            Preferimos crear cada curso a la medida, para así aprovechar al
            máximo la infraestructura, recursos y capacitaciones previas, así
            como enfocar cada tema a las necesidades específicas.
          </h5>
          <h6>
            Este método es una consultoría que busca identificar las necesidades
            que pudieran haber pasado desapercibidas por el cliente.
            Generalmente no incrementa la inversión, pero sí mejora la
            experiencia.
          </h6>
        </Jumbo>
    )
}
