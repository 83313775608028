import React, { Component } from "react";

import { Collapse } from "@material-ui/core";
import JumboBases from "./secMetodologia/JumboBases";
 
import CarruselB from "./secMetodologia/CarruselB";
import JumboCompetencias from "./secMetodologia/JumboCompetencias";
 

class Metodologia extends Component {
  render() {
    return (
      <div className="container">
        <Collapse></Collapse> 
        <JumboBases></JumboBases>
         
        <CarruselB></CarruselB>
        <JumboCompetencias></JumboCompetencias>
      </div>
    );
  }
}

export default Metodologia;
