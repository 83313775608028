import "./App.css";

import { Route, NavLink, HashRouter } from "react-router-dom";
import Metodologia from "./paginas/Metodologia";
import Holistica from "./paginas/Holistica";
import Home from "./paginas/Home";
import Filosofia from "./paginas/Filosofia";
import Automatizacion from "./paginas/Automatizacion";
import Microsoft from "./paginas/Microsoft";
import logo from "./RosetaITH.png";
import { makeStyles } from "@material-ui/core";
import Servicios from "./paginas/Servicios";
import AutomatizacionVBA from "./paginas/AutomatizacionVBA";

//import  { CssBaseline} from "@material-ui/core"
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/fondo01.jpg"})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    marginTop:"58px",
    minHeight:"100vh"
  }, 
}));
function App() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* <CssBaseline></CssBaseline> */}

      <HashRouter>
        <nav className="navbar fixed-top navbar-expand-lg  navbar-dark bg-dark">
          <NavLink className="nav-link " exact to="/">
            <img src={logo} height="30" alt="nada"></img>
            
          </NavLink>

          <div className="Comment-text"></div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              {/**/}    <li className="nav-item ">
                <NavLink className="nav-link" exact to="/Servicios">
                  Servicios
                </NavLink>
              </li> 
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/Metodologia">
                  Metodologia
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="."
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Capacitación
                </a>
                <div
                  className="dropdown-menu bg-dark"
                  aria-labelledby="navbarDropdown"
                >
                  <NavLink className="nav-link" exact to="/Microsoft">
                    Microsoft
                  </NavLink>
                  <NavLink className="nav-link" exact to="/Holistica">
                    Desarrollo Humano
                  </NavLink>
                  <div className="dropdown-divider"></div>
                  <NavLink className="nav-link" exact to="/Filosofia">
                    Filosofía
                  </NavLink>
                </div>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact to="/Automatizacion">
                  Automatización
                </NavLink>
              </li>
             
            </ul>
            {/*      <form className="form-inline my-2 my-lg-0" >
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="{props.home}"
                aria-label="Search"
              />
              <button
                className="btn btn-outline-success my-2 my-sm-0"
                type="submit"
              >
                Search
              </button>
            </form> */}
          </div>
        </nav>

        <div className="content">
          <Route exact path="/" component={Home} />
          <Route path="/Metodologia" component={Metodologia} /> <Route path="/Servicios" component={Servicios} />
          <Route path="/Holistica" component={Holistica} />{" "}
          <Route path="/Microsoft" component={Microsoft} />{" "}
          <Route path="/Filosofia" component={Filosofia} />{" "}
          <Route path="/Automatizacion" component={Automatizacion} />
          <Route path="/AutomatizacionVBA" component={AutomatizacionVBA} />
        </div>
      </HashRouter>
    </div>
  );
}

export default App;
