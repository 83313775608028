import React, { Component } from "react";
import CarruselA from "./secHome/CarruselA";
import JumboITH from "./secHome/JumboIth";

class Home extends Component {
  render() {
    return (
      <div className="container">
        <JumboITH></JumboITH>

        <CarruselA></CarruselA>
      </div>
    );
  }
}

export default Home;
