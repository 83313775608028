export const serviciosInformatica = [
  {
    titulo: "Automatización Excel",
    imgUrl: "",
    subtitulo: "Consultoría de Datos y procesos de información.",
    cuerpo:
      "Todo lo que se hace en Excel puede ser automatizado para que sea cuestión de segundos y no de horas. " +
      " \n" +
      "  Es muy común que se requieran mover columnas, limpiar filas, cambiar encabezados, consolidar rango y hacer fórmulas elaboradas, antes de iniciar el análisis.\nTodo eso puede ser automatizado para que Excel lo haga sin requerir nuestra atención.",
    pie:
      "Nosotros automatizamos tu trabajo, para que puedas dedicarte a la parte divertida de él.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Capacitación Microsoft 365",
    imgUrl: "",
    subtitulo: "Nadie nace sabiendo. Todos crecemos aprendiendo.",
    cuerpo:
      "Existe una tendencia a dejar que el usuario sea autodidacta y descubra las aplicaciones intuitivamente. Eso es bueno en parte, pero es costoso en tiempo, errores y calidad. " +
      " \n" +
      "La Capacitación acorta la curva de aprendizaje para acelerar la productividad.\nA veces nos soprender descubrir todo lo que no sabíamos que no sabíamos.",
    pie: "Cursos prácticos, de inmediata aplicación y significativos.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Habilidades de Instrucción en línea",
    imgUrl: "",
    subtitulo: "Comunicación virtual y sus oportunidades.",
    cuerpo:
      "Es tiempo en que debemos adaptarnos velozmente a la tecnología, en juntas de trabajo, en presentaciones con clientes y en capacitación externa o interna. " +
      " \n" +
      "No es suficiente con saber lo básico de las plataformas sino también poder aprovecharlas al máximo.",
    pie: "Zoom, Teams, Meet, etc.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Capacitación Google Workspace",
    imgUrl: "",
    subtitulo: "Ventajas y desventajas vs Microsoft.",
    cuerpo:
      "Existe una tendencia a dejar que el usuario sea autodidacta y descubra las aplicaciones intuitivamente. Eso es bueno en parte, pero es costoso en tiempo, errores y calidad. " +
      +" \n" +
      " La Capacitación acorta la curva de aprendizaje para acelerar la productividad." +
      " \n" +
      "A veces nos soprender descubrir todo lo que no sabíamos que no sabíamos.",
    pie: "Cursos prácticos, de inmediata aplicación y significativos.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Consultoría y Capacitación Power BI",
    imgUrl: "",
    subtitulo: "El complemento ideal de Excel.",
    cuerpo:
      "Excel puede hacer maravillas, pero PowerBi hace milagros. Se requieren conocimientos distintos para usar uno y otro y aunque las habilidades son similares, hay trucos que nunca aparecerán por instinto o sin dirección de alguien con experiencia.",
    pie: "Otro nivel en análisis de datos al alcance de todos.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
];

export const serviciosDesarrolloHumano = [
  {
    titulo: "Desarrollo y madurez de Equipos de Trabajo",
    imgUrl: process.env.PUBLIC_URL + "/img/imagen.jpg",
    subtitulo: "Más que sólo yo y sólo tú, somos nosotros.",
    cuerpo:
      "El equipo de trabajo, aunque vaya cambiando de integrantes, es una entidad en sí mismo que conlleva un proceso de evolución y madurez, cultura y tradición, que configura tácitamente la actitud, aptitud e intención de los participantes.",
    pie: ".",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Habilidades Gerenciales y Directivas",
    imgUrl: "",
    subtitulo: "El más alto, el más fuerte, el más rápido.",
    cuerpo:
      "Ya desde hace muchos años los puestos de mando dejaron de ser simples posiciones de organigrama; Hoy son pivotes de liderazgo, expertis y guía." +
      " \n" +
      "La genética juega una parte, pero las todas y cada una de las habilidades gerenciales y directivas pueden y deben ser aprendidas, revisadas y configuradas inteligentemente.",
    pie: "Sabe más el diablo por capacitación, que por experiencia.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Consultoría en Capacidades y Competencias.",
    imgUrl: "",
    subtitulo: "La Capacitación es la ampliación de la capacidad.",
    cuerpo:
      "El diseño organizacional y corporativo tomó un giro importante al basarse en Competencias. \nEs una nueva y mal explotada manera de leer la realidad de la productividad y la potencialidad de crecimiento." +
      " \n" +
      "Sin embargo dio mucha claridad del mapa de talentos que se requiere en cada lugar a cada momento.",
    pie: "Puedo, quiero, sé y debo.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Detección de Necesidades de Capacitación.",
    imgUrl: "",
    subtitulo:
      "Es mucho más lo que no sabemos que no sabemos, que lo que sabemos que no sabemos.",
    cuerpo:
      "Te ayudamos a afrontar la extenuante tarea de descubrir quién necesita aprender qué, cuándo puede aprenderlo, cuántos quieren saberlo y cómo es la estretegia ideal de proveer esa sustancia líquida increíble que es el conocimiento.",
    pie: "Desde el diseño del puesto hasta el holograma del talento.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Competencias de formación, instrucción y capacitación.",
    imgUrl: "",
    subtitulo: "Aprender a enseñar a la primera y disfrutarlo.",
    cuerpo:
      "Sabemos que son competencias muy distintas las que componen el saber hacer algo versus el saber transmitir ese conocimiento." +
      " \n" +
      "Aún así, el talento para trasmitir conocimiento, experiencia, preceptos y conceptos es a su vez, algo que se puede desarrollar y aprender.",
    pie: "Cuando el discípulo está listo, está listo el maestro.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Eventos y Conferencias temáticas por competencias.",
    imgUrl: "",
    subtitulo: "Conferencias interactivas inolvidables.",
    cuerpo:
      "Una opción es un show divertido y otra opción es un TEDtalk interesante. Un punto intermedio con lo mejor de ambos extremos es una conferencia interactiva que lúdicamente transmita un conocimiento y marque un día especial.",
    pie: "Desde 2 horas, hasta 3 días, todo es posible.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Acompañamiento y recursos de control de crisis.",
    imgUrl: "",
    subtitulo:
      "El confinamiento nos ha llevado al límite del equilibrio psicológico.",
    cuerpo:
      "La estabilidad emocional es un equilibrio que se ve afectado por las fuerzas externas que ejercen presión sobre lo que consideramos importante. " +
      " \n" +
      " Vencer esa presión, saber aprovecharla o desviarla y reconocer el aprendizaje en ese proceso son habilidades que quizá requieran acompañamiento de alguien que nos muestre cómo lograrlo.",
    pie: "Terapia de acompañamiento.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
];

export const serviciosConsciencia = [
  {
    titulo: "Jornada de Conscientización Juvenil",
    imgUrl: process.env.PUBLIC_URL + "/img/imagen.jpg",
    subtitulo: "Jóvenes entre 15 y 25 años",
    cuerpo:
      "Cursos intensivos en formato de retiro para jóvenes, impartido por jóvenes en un grupo dinámico y revolvente, que permiten que el participante se convierta en instructor, consolidando así su aprendizaje." +
      " \n" +
      "Con un amplio espectro de temas alcanzados, pero principalmente haciendo evidente que cambiar al mundo es posible.",
    pie: "Labor Fecunda.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Capacidades y Competencias transpersonales.",
    imgUrl: "",
    subtitulo: "Los No-Negociables.",
    cuerpo:
      "Desde hábitos simples y cotidianos hasta complejos procesos de consciencia son no sólo importantes, sino indispensables para el sano desarrollo de la personalidad, la identidad y la madurez." +
      " \n" +
      "En un mundo ideal, aprenderíamos a cuidar de estos aspectos durante los primeros años de vida, pero no estamos en un mundo ideal; por eso es útil repasarlos y descubrir qué más puedo hacer yo, por mí.",
    pie: "Valores de los niveles de consciencia.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Formación Filosófica para la vida cotidiana.",
    imgUrl: "",
    subtitulo: "La filosofía es como las matemáticas: está ahí siempre.",
    cuerpo:
      "Hoy sabemos que no todo el conocimiento es lo que nos enseñan en la escuela sino que gran parte es resultado de nuestros procesos de pensamiento, razonamiento, deducción y asociación analógica. En otras palabras, que la filosofía no es un estudio sino un sistema de creencias que determina nuestro comportamiento, actitud, aptitudes y valores.",
    pie: "Pienso, por lo tanto existo.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Acompañamiento de Conscientización.",
    imgUrl: "",
    subtitulo:
      "El confinamiento nos ha llevado al límite del equilibrio psicológico.",
    cuerpo:
      "La estabilidad emocional es un equilibrio que se ve afectado por las fuerzas externas que ejercen presión sobre lo que consideramos importante. " +
      " \n" +
      "Vencer esa presión, saber aprovecharla o desviarla y reconocer el aprendizaje en ese proceso son habilidades que quizá requieran acompañamiento de alguien que nos muestre cómo lograrlo.",
    pie: "Terapia de acompañamiento.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Competencias Parentales de Liderazgo.",
    imgUrl: "",
    subtitulo: "La verdadera escuela para Padres.",
    cuerpo:
      "No es verdad que nadie nos enseñó a ser padres: Nuestros padres lo hicieron. Quizá no lo hicieron del todo como nosotros hubiéramos deseado." +
      " \n" +
      "Aún estamos a tiempo de terminar de aprender y más importante es, que aún estamos a tiempo de ser mejores maestros parentales para nuestros hijos.",
    pie: "Liderazgo Parental.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Integración humana de Equipos de Trabajo.",
    imgUrl: "",
    subtitulo: "",
    cuerpo:
      "Así como hace ya un cuarto de siglo nos dimos cuenta que debíamos evolucionar de Grupos productivos a Equipos, hoy tenemos no sólo la oportunidad sino el deber de dar el siguiente paso para formar Equipos Evolucionados de Trabajo, con mejores seres humanos y paradigmas de cooperación más inteligentes.",
    pie: "Más que sólo yo y sólo tú, somos nosotros.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Liderazgo Holístico",
    imgUrl: "",
    subtitulo: "",
    cuerpo:
      "Un paradigma vanguardista y de profunda potencia en la formación de líderes, fundamentado en el desarrollo de consciencia, habilidades personales, interpersonales y transpersonales." +
      " \n" +
      "Más allá de las habilidades directivas o de toma de decisiones, están las capacidades, competencias, habilidades y conductas de quienes asumen su papel de liderazgo y se comprometen con un equipo.",
    pie: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
];

export const serviciosBase = [
  {
    titulo: "Software a la medida",
    subtitulo: "Web, Excel, App",
    cuerpo:
      "Ofrecemos soluciones en sistemas web administrativos, automatización en Excel, Access o Aplicaciones Windows o para móviles. Además de la consultoría para que el cliente defina sus necesidades, desarrollamos la solución justo a la medida.",
    pie: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Capacitacion Microsoft365",
    subtitulo: "Expertos en Excel",
    cuerpo:
      "MS-Excel es la aplicación de mayor demanda de capacitación en el ambiente corporativo. Impartimos cursos desde básico, avanzado y macros, así como laboratorios y Consultoría especializada.",
    pie: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
  {
    titulo: "Competencias Humanas y transpersonales",
    subtitulo: "Capacitación y Consultoría",
    cuerpo:
      "Desde los cursos que cotidianamente forman un equipo de trabajo o profesionalizan un área corporativa, hasta el diseño específico de cursos con alcances específicos, de corto, mediano y largo plazo.",
    pie: "",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 7,
  },
];
