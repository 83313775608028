import React, { Component } from 'react';
import AcordeonCursosMicrosoft from './secMicrosoft/AcordeonCursosMicrosoft';

import JumboMicrosoft from './secMicrosoft/JumboMicrosoft';
 
class Microsoft extends Component {
  render() {
    return (
      <div className="container-sm">
           <JumboMicrosoft/> 
          <AcordeonCursosMicrosoft/>
         
      </div>
    );
  }
}

export default Microsoft;

 