import { Card, CardContent } from '@material-ui/core'
import React from 'react'
import Jumbo from '../../Parciales/Jumbo'
import AcordeonServiciosBase from './AcordeonServiciosBase'
import AcordeonServiciosConsciencia from './AcordeonServiciosConsciencia'
import AcordeonServiciosDesarrolloHumano from './AcordeonServiciosDesarrolloHumano'
import AcordeonServiciosInformatica from './AcordeonServiciosInformatica'

export default function JumboServicios() {
    return (
      <Jumbo
        titulo="Servicios disponibles."
        lead="Nuestra metodología es amplia y versátil."
        texto="La profundidad de nuestra metodología nos permite una gran variedad de alcances, ser flexibles tanto en contenido como en técnica y ajustarnos a las necesidades específicas de cada cliente."
        accion="botón de acción"
      >
        <p>
          La capcitación técnica para el uso de aplicaciones de informática se
          realiza con técnicas de aprendizaje basadas en habilidades suaves y
          en atención a la experiencia significativa.
        </p>
        <p>
          La capacitación humana se apoya en el uso de técnicas y tecnología que
          van desde una correcta presentación en PowerPoint, hasta la
          transmisión vivencial de conceptos filosóficos, pasando por un
          inteligente lenguaje no verbal.
        </p>
        <Card >
          <CardContent>
            <h2>Generales</h2>
            <h6>Servicios de mayor demanda.</h6>
            <AcordeonServiciosBase />
          </CardContent>
        </Card>
        <p/>
        <Card>
          <CardContent>
            <h2>Informática</h2>
            <h6>Computación y datos.</h6>
            <AcordeonServiciosInformatica />
          </CardContent>
        </Card><p/>
        <Card>
          <CardContent>
            <h2>Desarrollo Humano</h2>
            <h6>Para RRHH y Equipos.</h6>
            <AcordeonServiciosDesarrolloHumano />
          </CardContent>
        </Card><p/>
        <Card>
          <CardContent>
            <h2>Desarrollo de Consciencia</h2>{" "}
            <h6>Inteligencia Total y Holística.</h6>
            <AcordeonServiciosConsciencia />
          </CardContent>
        </Card>
      </Jumbo>
    );
}
