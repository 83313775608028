import React from "react";
import Jumbo from "../../Parciales/Jumbo";
import AcordeonFundamentos from "./AcordeonFundamentos";

export default function JumboBases() {
  return (
    <Jumbo
      titulo="Bases de nuestra metodología"
      lead="Capacitación inteligente"
      texto="Todos nuestros cursos están basados en la metodología de la Inteligencia total y Holística, lo cual garantiza que el aprendizaje no sólo sea efectivo, eficiente y significativo sino que, además, detona procesos didácticos que a mediano plazo generan nuevo conocimiento, nuevas competencias y capacidades.
        No sólo construimos conocimiento a partir de la información, pues las competencias se anclan en diferentes niveles neurológicos y se fijan en procesos de toma de consciencia, por lo cual el participante aprende a crear nuevo conocimiento.
        "
      accion="botón de acción"
    >
      <h4>Fundamentos</h4>
      <h5>La huella genetica de todos nuestros servicios.</h5>
      <p>
        Toda competencia a desarrollar se fundamenta en un proceso interno de
        consciencia, que en equilibrio detona diversas conductas.
      </p>{" "}
      <p>
        Este proceso interno no tiene que ser evidente ni aparente, pero es
        indispensable para la capacitación.{" "}
      </p>{" "}
      <p>
        Los alcances de todos nuestros cursos están basados en este proceso.
      </p>
      <AcordeonFundamentos></AcordeonFundamentos>
    </Jumbo>
  );
}
