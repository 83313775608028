import React from "react";
import { serviciosDesarrolloHumano } from "../../constantes/servicios";
 
import Acordeon from "../../Parciales/Acordeon";
import { ordenarAleatorio } from "../../utilidades/aleatorio";


export default function AcordeonServiciosDesarrolloHumano() {
  const oa = ordenarAleatorio;
  oa(serviciosDesarrolloHumano, "aleatorio");
  return <Acordeon items={serviciosDesarrolloHumano}></Acordeon>;
}
