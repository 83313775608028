import React from 'react'
import JumboServicios from './secServicios/JumboServicios'

export default function Servicios() {
    return (
        <div className="container-md">
            <JumboServicios/>
        </div>
    )
}
