export const pasos = [
  {
    titulo: "Nos muestras tu trabajo",
    imgUrl: "",
    subtitulo: "¿En qué estás invirtiendo tu tiempo?",
    cuerpo:
      "Primero comprenderemos tus fuentes de datos, los procesos que hoy realizas con ellos, la transformación que les estás aplicando, los cálculos que aplicas y conoceremos lo que entregas, lo que recibes y el esfuerzo que esto conlleva.",
    pie:
      "Limpiar sábanas de datos, formular con funciones complejas, conciliar tablas, consolidar fuentes, etc.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 1,
  },
  {
    titulo: "Defines qué quieres obtener",
    imgUrl: "",
    subtitulo: "¿Cuál es el resultado ideal que buscas?",
    cuerpo:
      "Seguramente si no te llevara tanto tiempo y esfuerzo tendrías espacio, creatividad y tiempo para implementar mejoras, extender los resultados y reducir los plazos y tiempos de entrega.",
    pie: "La carta a los Reyes Magos de las hojas de Cálculo.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 2,
  },
  {
    titulo: "Te sugerimos mejoras",
    imgUrl: "",
    subtitulo: "¿En qué y cómo podríamos mejorar lo que imaginaste?",
    cuerpo:
      "Hasta ahora tus entregables han estado limitados a lo que sabes que puedes lograr de Excel, pero ahora vas a conocer lo que no sabías que se podía hacer y con tu expertis y nuestro conocimiento obtendremos de Excel más y mejores resultados.",
    pie: "Una vista fresca y nueva puede aportar ideas frescas y nuevas.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 3,
  },
  {
    titulo: "Automatizamos la solución",
    imgUrl: "",
    subtitulo:
      "¿Qué partes puede hacer Excel sin tu ayuda y en qué partes eres indispensable?",
    cuerpo:
      "El corazón de los beneficios de la automatización está en que con Funciones y Macros Excel absorba todo el esfuerzo, todas las rutinas tediosas, todas las decisiones de bajo nivel" +
      " \n" +
      "Así tú puedas disponer de tu tiempo y energía en analizar, deducir, decidir y asimilar la información haciendo una amalgama con tu conocimiento. Que es, seguramente, la parte más divertida de tu trabajo.",
    pie: "Programación en VBA de código abierto y fácil mantenimiento.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 4,
  },
  {
    titulo: "Ahorras más del 97% del tiempo.",
    imgUrl: "",
    subtitulo:
      "¿Qué proporción de tu trabajo hace Excel sin requerir tu atención?",
    cuerpo:
      "Aún cuando la automatización fuera simplemente la ejecución electrónica de las mismas tareas que hacías a mano, esto ya implicaría horas completas de ahorro." +
      " \n" +
      "Pero la automatización inteligente también permite transferir no sólo las tareas, sino las decisiones de bajo nivel que no requieran una valoración humana. Y más allá, la automatización no sólo reduce el tiempo de proceso, sino que reduce el factor de error de captura, por cansancio, por tedio, por herencia de labor o por variaciones en las fuentes de datos.",
    pie:
      "...y casi todo el 3% restante es esperar a que la máquina procese la instrucción.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 5,
  },
  {
    titulo: "Obtienes más de lo que pediste",
    imgUrl: "",
    subtitulo: "¿Sabías que Excel podía hacer todo eso?",
    cuerpo:
      "Durante el proceso de automatización somos flexibles, evolutivos y creativos, lo cual resulta en que al final del proceso, el resultado es mejor de lo que entre todos imaginamos al principio.",
    pie: "Para nosotros sería un fracaso el que sólo recibas lo que pediste.",
    aleatorio: Math.random() * (100 - 1) + 1,
    orden: 6,
  },
];