import React, { Component } from 'react';
import JumboConsciencia from './secFilosofia/JumboConsciencia';
import JumboFilosofia from './secFilosofia/JumboFilosofia';

class Filosofia extends Component {
  render() {
    return (
      <div className="container-sm">
          <JumboFilosofia/>
          <JumboConsciencia/>
      </div>
     
    );
  }
}

export default Filosofia;
