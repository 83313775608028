import React from "react";
import Acordeon from "../../Parciales/Acordeon";
import { cursosDesarrolloHumano } from "../../constantes/cursos";
import { ordenarAleatorio } from "../../utilidades/aleatorio";

export default function AcordeonCursosDh() {
  const oa = ordenarAleatorio;
  oa(cursosDesarrolloHumano, "aleatorio");
  return <Acordeon items={cursosDesarrolloHumano}></Acordeon>;
}
