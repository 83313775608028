import React from "react";
import { ordenarAleatorio } from "../../utilidades/aleatorio";

import BootstrapCarousel from "../../Parciales/BootstrapCarousel";
import { imagenesFrasesA } from "../../constantes/Imagenes";
export default function CarruselA() {
  const oa = ordenarAleatorio;
  oa(imagenesFrasesA, "aleatorio");
  return <BootstrapCarousel items={imagenesFrasesA}></BootstrapCarousel>;
}
