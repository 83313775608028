import React from "react";
import { serviciosBase } from "../../constantes/servicios";
 
import Acordeon from "../../Parciales/Acordeon";
import { ordenarAleatorio } from "../../utilidades/aleatorio";


export default function AcordeonServiciosBase() {
  const oa = ordenarAleatorio;
  oa(serviciosBase, "aleatorio");
  return <Acordeon items={serviciosBase}></Acordeon>;
}
