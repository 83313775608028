import React from "react";
import { ordenarAleatorio } from "../../utilidades/aleatorio";
import { imagenesFrasesB } from "../../constantes/Imagenes";
import {BootstrapCarousel} from "../../Parciales/BootstrapCarousel";
 
export default function CarruselB() {
  const oa = ordenarAleatorio;

  oa(imagenesFrasesB, "aleatorio");
  return <BootstrapCarousel items={imagenesFrasesB}></BootstrapCarousel>;
}
