import React from "react";
import Acordeon from "../../Parciales/Acordeon";
import { ordenarAleatorio } from "../../utilidades/aleatorio";
import { fundamentosITH } from "../../constantes/fundamentos";

export default function AcordeonFundamentos() {
  const oa = ordenarAleatorio;
  oa(fundamentosITH, "aleatorio");
  return <Acordeon items={fundamentosITH}></Acordeon>;
}
