import React from "react";
import { ordenarAleatorio } from "../../utilidades/aleatorio";

import BootstrapCarousel from "../../Parciales/BootstrapCarousel";
import {  imagenesVBA } from "../../constantes/Imagenes";
export default function GaleriaDH() {
  const oa = ordenarAleatorio;
  oa(imagenesVBA, "aleatorio");
  return <BootstrapCarousel items={imagenesVBA}></BootstrapCarousel>;
}
