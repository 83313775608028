import React from 'react'
import Jumbo from '../../Parciales/Jumbo'
import AcordeoncursosConsciencia from './AcordeoncursosConsciencia';

export default function JumboConsciencia() {
    return (
      <div className="container-sm">
        <Jumbo
          titulo="Desarrollo de Consciencia"
          lead="Conscientización"
          texto="Punto de contacto del ser con la realidad."
          accion="botón de acción"
        >
          <h5>Darme cuenta y tomar en cuenta.</h5>
          <h6>Parte de nuestra misión es participar en el crecimiento personal de todo aquel que así lo desee y esté dispuesto a expandir su consciencia. Nuestro enfoque es particular y personal, dejando todas las dimensiones del proceso en total control del participante.</h6>
           <AcordeoncursosConsciencia></AcordeoncursosConsciencia>
        </Jumbo>
        <></>
      </div>
    );
}
