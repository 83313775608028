 

export const ordenarAleatorio = (matriz_con_aleatorio,campo) => {
  return matriz_con_aleatorio.sort((a, b) => {
    if (a[campo] > b[campo]) {
      return 1;
    }
    if (b[campo] > a[campo]) {
      return -1;
    }
    return 0;
  });
};
