import React from 'react'
import Sec1 from './secAutomatizacionVBA/sec1'
 

function AutomatizacionVBA() {
    return (
        <div>
          
            <Sec1/>
          
        </div>
    )
}

export default AutomatizacionVBA
