import React, { Component } from "react";
import Tarjeta from "./Tarjeta";

class Acordeon extends Component {
  render() {
    return (
      <div className="accordion" id="accordionExample">
        {this.props.items.map((elemento, index) => {
          return (
            <div className="card" key={index}>
              <div className="card-header" id={"heading" + index}>
                <h2 className="mb-0">
                  <button
                    className={
                      "btn font-weight-bold text-info btn-block text-left" +
                      (index === 0 ? " collapsed " : " ")
                    }
                    type="button"
                    data-toggle="collapse"
                    data-target={"#collapse" + index}
                    aria-expanded="false"
                    aria-controls={"collapse" + index}
                  >
                    <h5>{elemento.titulo}</h5>
                  </button>
                </h2>
              </div>
              <div
                id={"collapse" + index}
                className="collapse "
                aria-labelledby={"heading" + index}
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  <Tarjeta titulo={elemento.subtitulo} pie={elemento.pie}>
                   
                    <div>
                    {elemento.cuerpo.split ('\n').map ((item, i) => <p key={i}>{item}</p>)}
                    </div>
                  </Tarjeta>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Acordeon;
