import React from "react";
import { serviciosInformatica } from "../../constantes/servicios";
import Acordeon from "../../Parciales/Acordeon";
import { ordenarAleatorio } from "../../utilidades/aleatorio";


export default function AcordeonServiciosInformatica() {
  const oa = ordenarAleatorio;
  oa(serviciosInformatica, "aleatorio");
  return <Acordeon items={serviciosInformatica}></Acordeon>;
}
